var Handlebars = require("../js/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"fas fa-heart\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"far fa-heart\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div class=\"mb-5\">\n    <div class=\"md:flex\">\n        <div class=\"max-w-lg w-full\">\n            <h2>\n                <a href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"post_link text-xl font-semibold text-gray-900\" target=\"_blank\"\n                    rel=\"noopener noreferrer\">\n                    "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n                </a>\n            </h2>\n\n            <div class=\"flex justify-between items-center w-full\">\n                <div class=\"flex items-center space-x-2\">\n                    <a data-toggle=\"tooltip\" title=\"Like post\">\n                        <span class=\"icon text-gray-600 text-s\">\n                            <span data-post-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-vote-upvoted=\""
    + alias4(((helper = (helper = helpers.upvoted || (depth0 != null ? depth0.upvoted : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"upvoted","hash":{},"data":data}) : helper)))
    + "\" class=\"vote_button\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.upvoted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </span>\n                        </span>\n                    </a>\n\n                    <img class=\"h-5 rounded\" src=\"https://avatars.githubusercontent.com/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "?s=64\">\n                    <a class=\"font-medium text-xs\" href=\"/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </a>\n                </div>\n\n                <!-- Ensure date is always on the right -->\n                <div class=\"text-xs text-gray-600 flex-shrink-0\">\n                    <a class=\"text-gray-600\" href=\"/post/"
    + alias4(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data}) : helper)))
    + "\">\n                        "
    + alias4(((helper = (helper = helpers.time || (depth0 != null ? depth0.time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data}) : helper)))
    + "\n                    </a>\n                </div>\n            </div>\n\n            <h3 class=\"text-gray-600 text-sm min-h-[40px]\">\n                "
    + alias4(((helper = (helper = helpers.summary || (depth0 != null ? depth0.summary : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summary","hash":{},"data":data}) : helper)))
    + "\n            </h3>\n        </div>\n    </div>\n</div>\n";
},"useData":true});